import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils/utils";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useRolesList() {
  // Use toast
  const toast = useToast();
  const { route, router } = useRouter();

  const refRoleListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { label: "nom", key: "name", sortable: true },
    { label: "Statut", key: "is_active", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalRoles = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const modulesData = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refRoleListTable.value
      ? refRoleListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRoles.value,
    };
  });

  const refetchData = () => {
    refRoleListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchRoles = (ctx, callback) => {
    store
      .dispatch("userGroup/fetchRoles", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const { roles, total } = response;
        
        callback(roles);
        totalRoles.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur lors de la récupération des rôles",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const addRole = (data) => {
    store
      .dispatch("userGroup/addRole", data)
      .then((response) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Le rôle a été ajouté avec succès.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        router.push({ name: "apps-roles-list" });
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Une erreur est survenue lors de l'ajout du rôle",
            icon: "AlertTriangleIcon",
            variant: "error",
          },
        });
      });
  };

  const updateRole = (data) => {
    store
      .dispatch("userGroup/updateRole", data)
      .then((response) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Le rôle a été mise à jour avec succès.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        router.push({ name: "apps-roles-list" });
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Une erreur est survenue lors de la modification du rôle",
            icon: "AlertTriangleIcon",
            variant: "error",
          },
        });
      });
  };

  const deleteRole = async (roleID) => {
    const { response } = await store.dispatch("userGroup/destroyRole", {
      id: roleID,
    });
    const data = response.data;
    if (data.status != 200) {
      toast({
        component: ToastificationContent,
        props: {
          title: data.error,
          icon: "AlertTriangleIcon",
          variant: "error",
        },
      });
    } else {
      toast({
        component: ToastificationContent,
        props: {
          title: "Le rôle a été supprimé avec succès.",
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
      refetchData();
    }
  };

  const onSubmit = (data) => {
    if (data.id) {
      updateRole(data);
    } else {
      addRole(data);
    }
  };

  const toggleRoleStatus = (checked, id) => {
    store
      .dispatch("userGroup/toggleRoleStatus", { id: id, checked: checked })
      .then((response) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Le statut du rôle a été mise à jour avec succès",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        });
        refetchData();
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur lors de la mise à jour du statut du rôle",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const fetchModules = () => {
    store
      .dispatch("userGroup/fetchModules")
      .then((modules) => {
        modulesData.value = modules;
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur lors de la récupération des modules",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchRoles,
    fetchModules,
    tableColumns,
    perPage,
    currentPage,
    totalRoles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRoleListTable,
    modulesData,
    toggleRoleStatus,
    refetchData,
    deleteRole,
    onSubmit,
  };
}
